import { tokens } from '@datacamp/waffles/tokens';

export const panelButtonPosition = {
  position: 'absolute',
  top: `-${tokens.spacing.xlarge}`,
  right: 0,
  zIndex: 3,
  height: 32,
  minWidth: 32,
  border: 0,
  borderRadius: `0 ${tokens.borderRadius.medium} 0 0`,
  '&::after': {
    margin: 2,
    borderRadius: `0 ${tokens.borderRadius.medium} 0 0`,
  },
} as const;

export const lightThemePanelButton = {
  borderLeft: `${tokens.borderWidth.thin} solid ${tokens.colors.white}`,
  color: tokens.colors.navy,
} as const;

export const darkThemePanelButton = {
  borderLeft: `${tokens.borderWidth.thin} solid ${tokens.colors.navy}`,
  color: tokens.colors.white,
} as const;

export const sidebarToggleButton = (isClosed: boolean) =>
  ({
    height: 32,
    width: 32,
    top: 0,
    left: isClosed ? -2 : undefined,
    color: tokens.colors.navy,
    borderTopLeftRadius: isClosed ? tokens.borderRadius.medium : 0,
  } as const);

export const headerIconButton = { color: tokens.colors.navy };
