import React, { Component } from 'react';

import ListViewContent from './ListViewContent';
import ListViewHeader from './ListViewHeader';
import ListViewSection from './ListViewSection';

type OwnProps = {
  headerHeight: number;
  headersJumpToSection?: boolean;
  headersStickAtBottom?: boolean;
  headersStickAtTop?: boolean;
  items?: Array<{
    content?: React.ReactElement | number | string;
    header?: React.ReactElement | number | string;
    sectionElementAttributes?: {
      [key: string]: string;
    };
  }>;
  onHeaderClick?: (...args: any[]) => any;
};

type State = {
  headersStickyState: Array<{
    isStickyAtBottom?: boolean;
    isStickyAtTop?: boolean;
  }>;
};

const defaultProps = {
  headersJumpToSection: true,
  headersStickAtTop: true,
  headersStickAtBottom: false,
};

type Props = OwnProps & typeof defaultProps;

export default class ListView extends Component<Props, State> {
  static defaultProps = defaultProps;

  headers: any;

  initTimeout: any;

  scroll: any;

  scrollbarWidth: any;

  wrapperRef: any;

  /**
   * Detect the width of the OS scrollbar.
   * We can substract it to the headers width when they become sticky.
   * Otherwise, headers will be over the scrollbars.
   *
   * Note that in OSX the user can choose different scrollbar
   * settings. If they are set to be "hidden", then the headers
   * will still appear above the scrollbar while scrolling.
   * We can't do much in that case.
   *
   * @return {Number} width of the scrollbar in pixels
   */
  static detectScrollbarWidth() {
    const scrollbarMeasureStyle = `
      width: 100px;
      height: 100px;
      overflow: scroll;
      position: absolute;
      top: -9999px;
    `;
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute('style', scrollbarMeasureStyle);
    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
  }

  /**
   * When instantiated, get refs to wrappers and headers
   */
  constructor(props: Props) {
    super(props);
    this.scroll = 0;
    this.headers = [];
    this.getHeaderRef = this.getHeaderRef.bind(this);
    this.getWrapperRef = this.getWrapperRef.bind(this);
    this.checkHeadersPosition = this.checkHeadersPosition.bind(this);
    this.onHeaderClick = this.onHeaderClick.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.state = { headersStickyState: [] };
  }

  /**
   * Set event listeners in order to update listview headers position.
   */
  componentDidMount() {
    const { headersStickAtBottom, headersStickAtTop } = this.props;
    this.scrollbarWidth = ListView.detectScrollbarWidth();
    if (headersStickAtTop || headersStickAtBottom) {
      this.wrapperRef.addEventListener('scroll', this.checkHeadersPosition);
      window.addEventListener('resize', this.checkHeadersPosition);
      this.initTimeout = setTimeout(() => {
        // Without setTimeout, React did not trigger the re-render.
        this.setState({
          headersStickyState: this.getHeadersStickyState(),
        });
      }, 0);
    }
  }

  componentWillUnmount() {
    const { headersStickAtBottom, headersStickAtTop } = this.props;
    clearTimeout(this.initTimeout);

    if (headersStickAtTop || headersStickAtBottom) {
      this.wrapperRef.removeEventListener('scroll', this.checkHeadersPosition);
      window.addEventListener('resize', this.checkHeadersPosition);
    }
  }

  componentDidUpdate(nextProps: Props) {
    if (this.props.items !== nextProps.items) {
      setTimeout(() => {
        // Forces react to update headers in case the content has changed.
        this.checkHeadersPosition();
      }, 0);
    }
  }

  getWrapperRef(wrapper: any) {
    this.wrapperRef = wrapper;
  }

  getHeaderRef(headerElement: any) {
    this.headers.push(headerElement);
  }

  /**
   * Determine headers "stickiness state".
   * @return {Array} Position and sticky info for each header.
   */
  getHeadersStickyState() {
    if (!this.wrapperRef) {
      return this.headers.map(() => ({
        isStickyAtBottom: false,
        isStickyAtTop: false,
      }));
    }
    const {
      headerHeight,
      headersStickAtBottom,
      headersStickAtTop,
    } = this.props;
    const listviewDimensions = this.wrapperRef.getBoundingClientRect();
    const listviewTop = listviewDimensions.top;
    const listviewHeight = listviewDimensions.height;
    const nHeaders = this.headers.length;

    return this.headers.map((header: any, index: any) => {
      const headerOffset = header.getBoundingClientRect().top - listviewTop;
      return {
        isStickyAtTop:
          headersStickAtTop && headerOffset <= index * headerHeight,
        isStickyAtBottom:
          headersStickAtBottom &&
          headerOffset >= listviewHeight - (nHeaders - index) * headerHeight,
      };
    });
  }

  scrollToSection(headerIndex: any) {
    const headerElement = this.headers[headerIndex];
    this.wrapperRef.scrollTop =
      headerElement.offsetTop -
      this.wrapperRef.offsetTop -
      headerIndex * this.props.headerHeight;
    setTimeout(() => {
      // Forces react to update headers in some edge cases.
      this.checkHeadersPosition();
    }, 0);
  }

  checkHeadersPosition() {
    if (this.wrapperRef) {
      const headersStickyState = this.getHeadersStickyState();
      const hasChanges = this.state.headersStickyState.some(
        (header: any, index: any) =>
          header.isStickyAtTop !== headersStickyState[index].isStickyAtTop ||
          header.isStickyAtBottom !==
            headersStickyState[index].isStickyAtBottom,
      );
      if (hasChanges) {
        this.setState({ headersStickyState });
      }
    }
  }

  onHeaderClick(headerIndex: any) {
    if (this.props.headersJumpToSection) {
      this.scrollToSection(headerIndex);
    }

    if (this.props.onHeaderClick) {
      this.props.onHeaderClick(headerIndex);
    }
  }

  render() {
    const { headerHeight, items } = this.props;
    const { headersStickyState } = this.state;

    return (
      <div className="listview__outer">
        <div className="listview__inner" ref={this.getWrapperRef}>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {items.map((item, index) => (
            <ListViewSection
              attributes={item.sectionElementAttributes}
              headerHeight={headerHeight}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              isLast={index === items.length - 1}
              key={index}
              sectionIndex={index}
            >
              <ListViewHeader
                headerIndex={index}
                getHeaderRef={this.getHeaderRef}
                height={headerHeight}
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                nHeaders={items.length}
                onClick={this.onHeaderClick}
                scrollbarWidth={this.scrollbarWidth}
                stickyState={headersStickyState && headersStickyState[index]}
              >
                {item.header}
              </ListViewHeader>
              <ListViewContent>{item.content}</ListViewContent>
            </ListViewSection>
          ))}
        </div>
      </div>
    );
  }
}
