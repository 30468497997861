import { matchPath } from 'react-router';

export const oldCampusRoute =
  '/:lang(es)?/:previewMode(preview)?/courses/:courseRef/:chapterRef?';
export const newCampusRoute =
  '/:lang(es)?/campus/:previewMode(preview)?/courses/:courseRef/:chapterRef?';

export const courseRefFromPath = (path: string): string | null => {
  const oldMatch = matchPath<{ courseRef: string }>(path, {
    path: oldCampusRoute,
  });
  if (oldMatch?.params.courseRef != null) {
    return oldMatch.params.courseRef;
  }

  const newMatch = matchPath<{ courseRef: string }>(path, {
    path: newCampusRoute,
  });
  if (newMatch?.params.courseRef != null) {
    return newMatch.params.courseRef;
  }

  return null;
};
